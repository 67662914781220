@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	&.sm_up_only {
		@include sm-down {
			display: none;
		}
	}

	@include sm-only {
		margin-top: 1.5rem;
	}
}

.product_title {
	@include headline6;
	margin-bottom: 0.25rem;
}

.financing_info {
	svg {
		max-height: 1em;
		margin-inline: 0.2ch;
	}

	@include tag1;
	color: #999;
}

.financing_info_inner {
	@include tag1;
}
