@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.pricing_info {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	@include headline8;

	del {
		color: #999;
	}

	margin-bottom: 0.5rem;

	&.small {
		font-size: 1rem;
	}
}

.discount_tag {
	@include orange_tag;
}
