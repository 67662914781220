@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	position: relative;
}

.error_box {
	margin: 1rem 0;

	display: flex;
	align-items: center;
	gap: 0.25rem;

	@include xs-only {
		justify-content: center;
	}

	p {
		@include body5;
		color: #df1c2f;
	}
}

.selection_title {
	@include headline8;
	margin-bottom: 0.5rem;
}

.selection_subtitle {
	@include headline10;
	color: #999;
	margin-bottom: 0.5rem;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0.14px;
}

.selection_header {
	margin-bottom: 1.25rem;
}

.container fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.modal_buttons {
	display: flex;
	gap: 1rem;

	margin: 1.25rem 0;

	justify-content: center;
	align-items: center;

	list-style: none;
	padding: 0;
}
