@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 0.5rem;

	&:global(.pod_container) > label:first-child :global(.subtitle_smallest),
	&:global(.pod_container) > label:nth-child(2) :global(.subtitle_smallest) {
		opacity: 0;
		pointer-events: none;
	}

	&:global(.autopilot_container) > label :global(.subtitle_smallest) {
		padding-top: 0;
	}
}
