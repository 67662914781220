@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	border-radius: 0.5rem;
	box-shadow: #d3d3d3 0 0 0 1px inset;

	transition: all 0.2s ease-out;
	position: relative;

	height: 100%;

	&:hover,
	&:focus-visible {
		background-color: rgba(230, 235, 255, 0.75);
	}
}

.highlight_tag {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);

	@include eyebrow2;

	border-radius: 0.25rem;
	padding: 0.3rem 0.6rem;
}

.standard_card {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.v_align {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&.pod_card {
		.standard_title {
			text-wrap: nowrap;
		}
		.standard_title,
		.standard_subtitle_small {
			text-wrap: nowrap;
		}
	}

	@include xs-only {
		&.pod_card {
			padding-left: 0.75rem;
			padding-right: 0.75rem;

			.badge {
				font-size: 0.5rem;
				padding: 0.25rem 0.5rem;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.standard_titles {
				padding-left: 0;
				padding-right: 0;
			}

			.standard_title {
				font-size: 1rem;
			}

			.standard_subtitle {
				@include body5;
			}

			.standard_subtitle_medium {
				@include body5;
			}

			.checklist li {
				font-size: 0.6rem !important;

				svg {
					width: 14px;
					height: 14px;
				}
			}
		}
	}

	@include max(400px) {
		padding-left: 0.35rem;
		padding-right: 0.35rem;

		.standard_titles {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.standard_titles {
	padding: 1rem;
	text-align: center;
}

.standard_title {
	@include headline9;
	margin-bottom: 0.25rem;

	@include xs-only {
		font-size: 1rem;
	}
}

.standard_subtitle {
	@include body5;

	@include sm-down {
		@include body6;
	}
}

.standard_subtitle_medium {
	@include body4;
}

.standard_subtitle_small {
	@include body6;
}

.standard_subtitle,
.standard_subtitle_small,
.standard_subtitle_medium,
.standard_subtitle_smallest {
	color: #999;
	margin-bottom: 0.25rem;

	&:empty {
		display: none;
	}
}

.checklist {
	list-style: none;

	margin: 0;
	padding: 0.75rem 0;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	@include xs-only {
		padding: 0 0 0.75rem;
	}
	li {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 0.25rem;
		align-items: start;

		@include body6;
		line-height: 1.1; // TODO out of spec
		color: #999;
	}
}

.badge {
	color: #fff;
	background-color: #0038ff;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.4;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 5px 10px;
	border-radius: 4px;

	position: absolute;
	top: -11px;
	left: 50%;
	transform: translate(-50%, 0%);
	@include xs-only {
		font-size: 0.5rem;
		padding: 0.25rem 0.5rem;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.standard_subtitle_smallest {
	@include body6;
	font-size: 10px;
	padding-top: 12px;
	white-space: nowrap;
}
