@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$padding-desktop: 10rem;

.container {
	background-color: $white;
	color: $black;

	padding: $padding-desktop;
	padding-left: 5rem;
	padding-right: 5rem;

	@include sm-down {
		padding: 1.5rem;
	}

	@include xs-only {
		padding: 1.25rem;
	}

	position: relative;
}

.carousel_wrapper {
	:global(.connected-title) {
		margin-bottom: 18px;
		h2 {
			font-size: 1.5rem;
		}
		@include sm-up {
			display: none;
		}
	}

	@include md-up {
		margin-bottom: 30vw;
	}
	> div {
		position: sticky;
		top: $padding-desktop;
	}

	&.mobile_only {
		@include md-up {
			display: none;
		}
	}
}

.container_inner {
	display: grid;
	justify-content: space-between;
	grid-template-columns: 50% 47%;
	@include min(1300px) {
		grid-template-columns: 60% 35%;
	}

	margin-inline: auto;

	@include sm-down {
		grid-template-columns: 1fr;
		row-gap: 0;
	}
}

.sections {
	display: flex;
	flex-direction: column;
	gap: 20vh;

	@include xs-only {
		:global(.connected-title) {
			display: none;
		}
	}

	@include sm-down {
		gap: 2rem;
	}
}

.first_section {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.side_nav_outer {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;

	z-index: 5;

	pointer-events: none;

	@include sm-down {
		display: none;
	}
}

.side_nav_container {
	position: sticky;
	top: 0;
	margin-right: 1rem;
	height: 100svh;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	* {
		pointer-events: auto;
	}
}
