@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

.container {
	box-shadow: transparentize($neutral-black, 0.8) 0 0 0 1px;
	cursor: pointer;
	background-color: $neutral-white;
	color: $neutral-black;
	padding: 1.5rem;

	border-radius: 6px;

	h3.product_title {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0.18px;
		line-height: 1.4;
		margin-bottom: 8px;
		@include sm-down {
			margin-bottom: 4px;
		}
	}

	@include sm-down {
		// box-shadow: none;
		padding: 16px 16px;
	}
}

.top {
	display: flex;
	> div:first-child {
		width: 32px;
		padding-top: 2px;
		flex-shrink: 0;
	}

	img {
		max-height: 4.5rem;
	}
}

.title {
	@include subheading3;
	margin-bottom: 0.25rem;
	@include sm-down {
		margin-bottom: 12px;
	}
}

.description {
	@include body7;
	color: #797c8e;
	margin-bottom: 24px;
	max-width: 355px;
	@include sm-down {
		display: none;
	}
}

.inner {
	@include sm-down {
		box-shadow: transparentize($neutral-black, 0.8) 0 0 0 1px;

		border-radius: 6px;

		display: grid;
		grid-template-columns: 40fr 60fr;
		align-items: center;
		gap: 1rem;
	}
}

.image {
	display: block;
	margin-bottom: 2rem;
}

.bottom_section {
	display: grid;
	grid-template-columns: auto auto;
	gap: 1rem;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		padding: 1.5rem;
		padding-left: 0;
	}
}

.info {
	> h4 {
		@include subheading5;
		margin-bottom: 0.25rem;
	}
}

.product_description {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0.14px;
	max-width: 390px;

	color: #797c8e;
	margin-bottom: 10px;
	@include sm-down {
		margin-bottom: 4px;
	}
}

.checkbox_container {
}

.checkbox {
	padding: 0.5rem 1.5rem;
	width: 8rem;

	cursor: pointer;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	font: inherit;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 140%;

	border-radius: 6px;
	box-shadow: #cdcdd3 0 0 0 1px;

	span {
		overflow: hidden;
		white-space: nowrap;
		transition: all 0.25s ease-in-out;
	}
}

.checkmark {
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50rem;
	display: flex;
	justify-content: center;
	align-items: center;

	background: $brand-secondary;

	svg {
		width: 100%;
		height: 100%;
	}
}

.modal_button {
	margin-top: 2rem !important;
}

.bottom {
	margin-top: 10px;
	// padding-top: 16px;
	position: relative;
	// &:before {
	// 	content: ' ';
	// 	height: 1px;
	// 	width: calc(100% - 32px);
	// 	background-color: #cdcdd3;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 32px;
	// 	display: block;
	// }
}

.iconrow {
	display: flex;
	padding-left: 32px;
	img {
		width: 24px;
		height: 24px;
		padding-top: 3px;
		margin-right: 8px;
	}
	p {
		color: #797c8e;
		text-align: center;
		margin-bottom: 0px;
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
	}
}

.pricing {
	strong {
		color: #797c8e;
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
	}
	s {
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}
	span {
		color: #0249a0;
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
		margin-top: 0;
	}
}
