@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/fonts-decorated';

.label {
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	justify-content: center;

	text-align: center;
	flex: 1;

	box-shadow: inset $neutral-3 0 0 0 1px;
	background-color: #fff;

	border-radius: 5px;
	padding: 0.75rem;

	transition: box-shadow 0.25s ease;

	&:hover {
		cursor: pointer;
		box-shadow: inset $neutral-1 0 0 0 1px;
	}

	&:focus-visible {
		box-shadow: inset $neutral-1 0 0 0 1px;
	}

	span.price_label {
		font-size: 16px;
		font-weight: bold;
	}
}

.label_old {
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	justify-content: center;

	text-align: left;

	box-shadow: inset rgb(0 0 0 / 10%) 0 0 0 1px;
	background-color: #fff;

	border-radius: 5px;

	min-height: 70px;
	min-width: 100%;

	padding: 16px;

	&:hover {
		cursor: pointer;
		box-shadow: inset #0038ff 0 0 0 2px;
	}

	@include sm-up {
		min-width: 100px;
		min-height: 106px;
		text-align: center;
		padding: 22px 8px;
		flex: 1;
	}

	:global(.badge_v2) {
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.3px;

		background: linear-gradient(113.7deg, #1862ff -64.08%, #ff00d6 220.18%);
		color: $brand-primary;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	span.price_label {
		font-size: 1rem;
		font-weight: 400;
		line-height: 110%;

		@include xs-only {
			font-size: 1rem;
			font-weight: 400;
			line-height: 140%;
			letter-spacing: 0.01rem;
		}
	}
}

input:focus-visible + .label_old {
	box-shadow: #002173 0 0 5px 5px !important;
	background-color: #f2f4f6;
}

input:checked + .label_old {
	box-shadow: inset #0038ff 0 0 0 2px;
	background-color: #e6ebff;
}

input:focus-visible + .label {
	box-shadow: $neutral-2 0 0 5px 5px !important;
}

input:checked + .label {
	box-shadow: inset $solid-blue 0 0 0 1px;
	outline: 1px solid $solid-blue;
}

.titleAndDescription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-basis: 100%;
}

.title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	@include subheading5;
	text-align: center;
}

.title_old {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2px;

	font-size: 1.125rem;
	font-weight: 500;
	line-height: 110%;

	@include sm-up {
		flex-direction: column-reverse;
		margin-top: 2px;
	}

	@include xs-only {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 140%;
		letter-spacing: 0.01125rem;
	}
}

.description_old {
	display: block;
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	color: #898989;
	margin-top: 4px;
}

.description {
	display: block;
	@include body8;
	margin-top: 0.25rem;
	color: #797c8e;
	letter-spacing: -0.2px;

	:global(ul) {
		text-align: left;
		display: inline-block;
		width: auto;
		margin: 5px auto;
		font-size: 11px;
		list-style-image: none;
		list-style-type: none;

		@include xs-only {
			font-size: 10px;
		}

		> li {
			margin-bottom: 3px;
			display: flex;
			align-items: center;
			column-gap: 5px;

			&::before {
				content: ' ';
				background-image: url('https://eightsleep.imgix.net/icons_check2.svg?v=1650776727');
				display: inline-block;
				width: 15px;
				height: 15px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}

.fieldset {
	border: none;
	padding: 0;
	display: grid;
	gap: 0.5rem;
	margin-top: 15px;
}

.fieldset_old {
	border: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	flex: 1;
	gap: 6px;
}

.subtitle {
	font-size: 12px;
	line-height: 140%;
	font-weight: bold;
	color: #898989;

	.subtitle2 {
		font-weight: 400;
		display: block;
	}

	@include xs-only {
		.subtitle2 {
			margin-left: 5px;
		}
	}

	@include sm-up {
		.subtitle2 {
			display: block;
		}
	}
}

.compare_price {
	text-decoration: line-through;
	opacity: 0.8;
}

.badge {
	@include orange_tag;
}

.pricing_info {
	display: flex;
	justify-content: center;
	align-items: center;

	gap: 0.25rem;
	margin: 0.25rem 0 0;

	span {
		color: #797c8e;
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
	}
	del {
		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	em {
		color: #0249a0;
		font-size: 11px;
		font-style: normal;
		font-weight: 700;
		margin-top: 0;
	}
}
