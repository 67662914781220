@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.modal_wrapper {
	padding: 2rem;

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	transition: all 0.2s ease-out;

	z-index: 999999;

	@include sm-down {
		padding: 2rem 0;
	}
}

.container {
	margin-inline: auto;

	// min-width: 66vw;
	width: min(calc(100vw - 20px), 750px);
	max-height: 100%;
	overflow: auto;

	border-radius: 0.5rem;

	background-color: $white;
	color: $black;

	> * {
		padding: 6rem 1.5rem;

		@include xs-only {
			padding: 3rem 0;
		}
	}

	transition: all 0.2s ease-out;

	box-shadow: 0 1237px 346px 0 rgba(0, 0, 0, 0), 0 791px 317px 0 rgba(0, 0, 0, 0.02), 0 445px 267px 0 rgba(0, 0, 0, 0.06), 0 198px 198px 0 rgba(0, 0, 0, 0.11), 0 49px 109px 0 rgba(0, 0, 0, 0.13);
}

.container h2 {
	@include headline6;
	text-align: center;

	max-width: 13ch;
	margin-inline: auto;

	margin-bottom: 5rem;
}

.inset_fixed_container {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	padding: 0;

	z-index: 10;
}

.close_modal_button.close_modal_button {
	border-radius: 0.5rem;

	box-shadow: inset black 0 0 0 2px;
	background: rgba(255, 255, 255, 0.5);

	padding: 0.5rem;

	line-height: 0;

	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
}
