@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

$desktop-right-padding: 6rem;
$desktop-left-padding: 2rem;
$top-space: 10rem;
$selections-size: 32rem;
$grid-gap: 3.5rem;

.pdp_container {
	background-color: $neutral-white;
	color: $neutral-black;

	padding: 0 $desktop-right-padding 6rem $desktop-left-padding;

	display: flex;
	justify-content: center;
	align-items: center;

	@include sm-down {
		padding: 0 1.5rem 2rem;
	}

	position: relative;
}

.pricing {
	display: inline-flex;
	align-items: center;

	strong {
		@include subheading3;
		color: $neutral-black;
	}

	s {
		font-size: 1.25rem;
		color: #797c8e;
		margin-left: 0.25rem;
	}

	span {
		@include subheading5;
		color: $bfcm-2023-blue;
		margin-left: 0.5rem;
		margin-bottom: 0.05rem;
	}

	margin-bottom: 0;
}

.pricing_google {
	strong {
		@include subheading2;
		color: $neutral-black;
	}

	s {
		font-size: 1rem;
	}
}

.selection_container_error {
	&::after {
		content: '';
		position: absolute;
		inset: -1rem;
		box-shadow: red 0 0 0 1px;
		border-radius: 6px;
		z-index: -1;
	}
}

.selection_container {
	> h2 {
		@include subheading3;
		margin-bottom: 0.25rem;
	}

	> p {
		@include body7;
		color: #797c8e;
		margin-bottom: 0.5rem;

		// max-width: 355px;
	}

	@include sm-down {
		> h2 {
			@include subheading3;
			margin-bottom: 0.125rem;
		}

		> p {
			@include body7;
			color: #797c8e;
			margin-bottom: 1rem;
		}
	}
}

.modal_button {
	@include subheading6;
	color: $neutral-2;
	text-decoration: underline;
	cursor: pointer;
	margin: 1rem auto !important;
	display: flex;

	svg {
		height: 1rem !important;
		width: 1rem !important;
	}
}

.small_pricing {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.3rem;
	margin-bottom: 0;

	strong {
		@include subheading5;
	}

	s {
		@include subheading5;
		color: #797c8e;
	}

	span {
		@include subheading6;
		font-weight: 500;
		color: $bfcm-2023-blue;
		margin-top: 0.3ch;
	}
}

.mobile_image {
	border-radius: 6px;
	overflow: hidden;
	display: block;
	margin-bottom: 1.5rem;

	* {
		display: block;
		width: 100%;
	}

	@include md-up {
		display: none;
	}
}

.mobile_whats_included {
	background: $neutral-white;
	color: $neutral-black;
	padding: 0 1.25rem;
	@include md-up {
		display: none;
	}

	h2 {
		@include subheading2;
		text-align: center;
		margin-bottom: 1.5rem;
	}

	padding-bottom: 2rem;
}

.shop_pay_button {
	box-shadow: inset black 0 0 0 2px;
	border-radius: 6px;

	svg {
		height: 1.5rem;
		width: 4rem;
		left: -20px;
	}

	> div svg {
		position: relative;
	}
}

.savings_badge {
	background-color: $neutral-8;
	padding: 4px 8px;

	border-radius: 4px;

	@include body7;

	// TODO are we doing this now? it's not the original orange _and_ it's a different font weight

	color: $bfcm-2023-blue;
	white-space: nowrap;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 14px */
	letter-spacing: 0.633px;
	text-transform: uppercase;
	margin-bottom: 5px;

	@include sm-down {
		margin-left: -5px;
	}

	cursor: pointer;
	display: flex;
	column-gap: 5px;
	align-items: center;
	justify-content: space-between;

	.arrow_down {
		cursor: pointer;
		opacity: 0.6;

		> img {
			width: 20px;
			height: 20px;
		}
	}
}

.mobile_benefits {
	background-color: $neutral-white;
	color: $neutral-black;

	padding: 2rem;

	@include md-up {
		display: none;
	}
}
