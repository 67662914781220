@import 'styles/mixins/media-queries';

.visual {
	@include md-up {
		position: absolute !important;
		top: 0 !important;
	}
	transition: opacity 300ms;
	opacity: 0;
	@include sm-down {
		display: none;
	}

	&.selected {
		opacity: 1;
		@include sm-down {
			display: block;
		}
	}
}

.carousel_photo {
	display: block;
	width: 100%;
	height: 100%;
	@include sm-down {
		margin-bottom: 1.5rem;
	}
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.carousel_video {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
